import validate from "/home/ploi/luidruchtig.wijsneus.app/node_modules/nuxt/dist/pages/runtime/validate.js";
import welcome_45global from "/home/ploi/luidruchtig.wijsneus.app/middleware/welcome.global.ts";
import manifest_45route_45rule from "/home/ploi/luidruchtig.wijsneus.app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  welcome_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "registration-enabled": () => import("/home/ploi/luidruchtig.wijsneus.app/middleware/registration-enabled.ts"),
  "sanctum:unverified": () => import("/home/ploi/luidruchtig.wijsneus.app/middleware/sanctum:unverified.ts"),
  "sanctum:verified": () => import("/home/ploi/luidruchtig.wijsneus.app/middleware/sanctum:verified.ts"),
  "sanctum:auth": () => import("/home/ploi/luidruchtig.wijsneus.app/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.auth.js"),
  "sanctum:guest": () => import("/home/ploi/luidruchtig.wijsneus.app/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.guest.js")
}